import React, { useState } from "react";
import "./InputContainerStyles.scss"
import userIcon from "../../../../Assets/Icons/show.png";
import pwdIcon2 from "../../../../Assets/Icons/unshow.png";

const InputContainer = (props) => {
    const [isFocus, setIsFocus] = useState(false)
    const [showPwd, setShowPwd] = useState(false);

    return (
        <div>
            <div
                className={`InputContainer 
                ${props.type === "username" || props.type === "password" || props.type === "confirmPass" ? "InputUsername" : ""}`}
                style={{ borderColor: props.success ? "#51B62D" : props.error ? "#D85757" : isFocus ? "#DADADA" : "", height: props.height ? props.height : "" }}>
                <input
                    className="zp-input-container"
                    placeholder={props.placeholder}
                    value={props.value} type={(props.type === "password" || props.type === "confirmPass") && !showPwd ? "password" : "text"}
                    onFocus={() => {
                        setIsFocus(true)
                        if (props.onFocus) {
                            props.onFocus()
                        }

                    }}
                    onBlur={() => {
                        setIsFocus(false)
                        if (props.onBlur) {
                            props.onBlur()
                        }
                    }}
                    onChange={e => {
                        props.onChange(e)
                    }}

                />
                <div className="icon-container">
                    {props.type === "password" ? (
                        <span
                            for="fname"
                            className="icon"
                            onClick={() => {
                                if (showPwd) {
                                    setShowPwd(false);
                                } else {
                                    setShowPwd(true);
                                }
                            }}
                        >
                            {showPwd ? (
                                <img src={pwdIcon2} alt="" />
                            ) : (
                                <img src={userIcon} alt="" />
                            )}
                        </span>
                    ) : (
                        ""
                    )}

                    {props.type === "confirmPass" ? (
                        <span
                            for="fname"
                            className="icon"
                            onClick={() => {
                                if (showPwd) {
                                    setShowPwd(false);
                                } else {
                                    setShowPwd(true);
                                }
                            }}
                        >
                            {showPwd ? (
                                <img src={pwdIcon2} alt="" />
                            ) : (
                                <img src={userIcon} alt="" />
                            )}
                        </span>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            {props.error ?
                <div className="required-text">
                    {props.errorText}
                </div> : ""}
            {props.success ?
                <div className="success-text">
                    {props.successText}
                </div> : ""}
        </div>
    )

}
export default InputContainer