import React, { useState } from "react";
import "../SignupWrapper/SignupWrapperStyles.scss";
import done from "../../../../Assets/Icons/done.png";
// import copy from "../../../../Assets/Icons/copy.png";

import SignupButtonContainer from "../SignupButtonContainer/SignupButtonContainer";
import config from "../../../../config"


const SignupWrapperFive = (props) => {
    const [copied, setCopied] = useState(false)

    return (
        <div className="SignupWrapper">
            <div className="SignupWrapper-container">
                <div className="success-container">
                    <div className="success-wrapper">
                        <div className="success-icon-container">
                            <img className="success-icon" alt="" src={done} />
                        </div>
                        <div className="success-header">
                            Successful
                        </div>
                        <div className="success-subheader">
                        Your business email <span onClick={() => {
                                setCopied(true)
                                navigator.clipboard.writeText(props.email)
                                setTimeout(() => {
                                    setCopied(false)
                                }, 1000);
                            }} >{props.email}
                                {copied ? <div className="copy">Copied</div> : ""}
                            </span> is successfully created.
                        </div>
                        <div className="btn-container-center">
                            <SignupButtonContainer title="Login to Zapmail" primary onClick={() => {
                                window.location.href = `${config.domain.app}`;
                            }} />
                        </div>
                    </div>


                </div>

            </div>
        </div>

    )
}
export default SignupWrapperFive
