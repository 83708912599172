import React, { useState } from "react";
import "../SignupWrapper/SignupWrapperStyles.scss";
import logo from "../../../../Assets/Images/logo-new.png";
import back from "../../../../Assets/Icons/back.png";
import InputContainer from "../InputContainer/InputContainer";
import SignupButtonContainer from "../SignupButtonContainer/SignupButtonContainer";



const SignupWrapperTwo = (props) => {
    const [emailError, setEmailError] = useState(false)
    const [errorText, setErrorText] = useState("* Required")

    const validateEmail = () => {
        return props.email
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const onNextClick = () => {
        if (props.email !== "") {
            if (validateEmail()) {
                props.setSelectedPage(3)
                setEmailError(false)
            } else {
                setEmailError(true)
                setErrorText("Invalid email format")
            }
        } else if (props.email === "") {
            setEmailError(true)
            setErrorText("* Required")
        }
    }



    return (
        <div className="SignupWrapper">
            <div className="SignupWrapper-container">
                <div className="header-container">
                    <div className="logo-container">
                        <img alt="" src={back} className="back-icon" onClick={() => {
                            props.setSelectedPage(1)
                        }} />
                        <img alt="" src={logo} className="logo" />
                    </div>
                    <div className="signup-header">
                        Add a Secondary Email
                    </div>
                    <div className="signup-subheader">
                        This will be used to recover your account
                    </div>
                    <div>
                        <InputContainer placeholder="Secondary email address"
                            value={props.email} type="email"
                            onChange={e => {
                                props.setEmail(e.target.value)
                                setEmailError(false)
                            }} error={emailError} errorText={errorText} />
                    </div>
                    <div className="btn-container-right">
                        <SignupButtonContainer title="Next" primary onClick={onNextClick} />
                    </div>

                </div>

            </div>
        </div>
    )
}
export default SignupWrapperTwo
