import React, { useState } from "react";
import "./SignupWrapperStyles.scss";
import logo from "../../../../Assets/Images/logo-new.png";
import check from "../../../../Assets/Icons/check.png"
import InputContainer from "../InputContainer/InputContainer";
import SignupButtonContainer from "../SignupButtonContainer/SignupButtonContainer";
// import DatePicker from "../DatePicker/DatePicker";
// import moment from "moment";



const SignupWrapper = (props) => {
    const [firstNameError, setFirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)
    const [agreeError, setAgreeError] = useState(false)
    const [firstNameErrorText,setFirstNameErrorText]=useState("")
    const [lastNameErrorText,setLastNameErrorText]=useState("")



    const onNextClick = () => {

        if (props.firstName === "") {
            setFirstNameErrorText("* Required")
            setFirstNameError(true)
        } if (props.lastName === "") {
            setLastNameErrorText("* Required")
            setLastNameError(true)
        }
        if(props.firstName.length<4){
            setFirstNameErrorText("Your Business Name should contain atleast 4 characters")
            setFirstNameError(true)
        }
        if(props.lastName.length<4){
            setLastNameErrorText("Your Display Name should contain atleast 4 characters")
            setLastNameError(true)
        }
        if (!props.agree) {
            setAgreeError(true)
        }
        if (props.firstName !== "" && props.lastName !== "" && props.agree&&props.firstName.length>=4&&props.lastName.length>=4) {
            props.setSelectedPage(2)
        }


    }


    return (
        <div className="SignupWrapper">
            <div className="SignupWrapper-container">
                <div className="header-container">
                    <div className="logo-container">
                        <div className="back-icon"/>
                        <img alt="" src={logo} className="logo" />
                    </div>
                    <div className="signup-header">
                        Create a Free Business Account
                    </div>
                    <div className="signup-subheader">
                        Enter your business details
                    </div>
                    <div>
                        <InputContainer
                            placeholder="Business Name"
                            value={props.firstName}
                            type="firstName"
                            onChange={e => {
                                props.setFirstName(e.target.value)
                                setFirstNameError(false)

                            }} error={firstNameError}
                            errorText={firstNameErrorText} />
                        <InputContainer
                            placeholder="Display Name"
                            value={props.lastName}
                            type="lastName"
                            onChange={e => {
                                props.setLastName(e.target.value)
                                setLastNameError(false)

                            }} error={lastNameError} errorText={lastNameErrorText} />

                        <div className="sub-text">This will be visible to your email recipients</div>
                        <div className=
                            {`check-container ${agreeError ? "check-error" : ""}`} onClick={() => {
                                props.setAgree(!props.agree)
                                setAgreeError(false)
                            }}>
                            <div className={`check-box ${agreeError ? "checked-error" : props.agree ? "checked" : ""}`}>
                                {props.agree &&
                                    <img alt="" src={check} className="check" />}
                            </div>
                            I’m authorized to create business emails on my company’s behalf</div>
                    </div>
                    <div className="btn-container-right">
                        <SignupButtonContainer title="Next" primary onClick={onNextClick} />
                    </div>

                </div>

            </div>
        </div>
    )
}
export default SignupWrapper
