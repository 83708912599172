import React, { useState } from "react";
import SignupWrapper from "./SignupWrapper/SignupWrapper";
import SignupWrapperTwo from "./SignupWrapperTwo/SignupwrapperTwo";
import SignupWrapperThree from "./SignupWrapperThree/SignupWrapperThree";
import SignupWrapperFour from "./SignupWrapperFour/SignupWrapperFour";
import DomainWrapper from "./DomainWrapper/DomainWrapper";
import SignupWrapperFive from "./SignupWrapperFive/SignupWrapperFive";
const BusinessSignup = () => {
    const [selectedPage, setSelectedPage] = useState(1)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [domain, setDomain] = useState("")
    const [agree, setAgree] = useState(false)
    const [agreeToTerms, setAgreeToTerms] = useState(false)

    const getSignupWrapper = () => {
        return <SignupWrapper setSelectedPage={setSelectedPage}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            agree={agree}
            setAgree={setAgree}
        />
    }


    const getPage = () => {
        let page = getSignupWrapper()
        switch (selectedPage) {
            case 1:
                page = getSignupWrapper()
                break;
            case 2:
                page = <SignupWrapperTwo
                    setSelectedPage={setSelectedPage}
                    email={email}
                    setEmail={setEmail} />
                break;
            case 3:
                page = <DomainWrapper
                    setSelectedPage={setSelectedPage}
                    setDomain={setDomain} />
                break;
            case 4:
                page = <SignupWrapperThree
                    setSelectedPage={setSelectedPage}
                    username={username}
                    setUsername={setUsername}
                    domain={domain} />
                break;
            case 5:
                page = <SignupWrapperFour setSelectedPage={setSelectedPage}
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    username={username}
                    domain={domain}
                    password={password}
                    setPassword={setPassword}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                    agreeToTerms={agreeToTerms}
                    setAgreeToTerms={setAgreeToTerms}
                />
                break;
            case 6:
                page = <SignupWrapperFive email={`${username.toLowerCase()}@${domain}`} />
                break;
            default:
                page = getSignupWrapper()
                break;
        }
        return page
    }
    return (
        getPage()
    )

}
export default BusinessSignup