import React, { useState, useContext } from "react";
import "../SignupWrapper/SignupWrapperStyles.scss";
import logo from "../../../../Assets/Images/logo-new.png";
import back from "../../../../Assets/Icons/back.png";
import InputContainer from "../InputContainer/InputContainer";
import SignupButtonContainer from "../SignupButtonContainer/SignupButtonContainer";
import AppContext from "../../../../ContextApi/AppContext";
import check from "../../../../Assets/Icons/checked.png";
import cross from "../../../../Assets/Icons/cross.png";
import agreeIcon from "../../../../Assets/Icons/check.png"




const SignupWrapperFour = (props) => {
    const [passwordError, setPasswordError] = useState(false)
    const [passwordErrorText, setPasswordErrorText] = useState("* Required")
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState("* Required")
    const [passwordRequirementError, setPasswordRequirementError] = useState(false)
    const [pwdLength, setPwdLength] = useState(false);
    const [upperCase, setUpperCase] = useState(false);
    const [lowerCase, setLowerCase] = useState(false);
    const [specialChar, setSpecialChar] = useState(false);
    const [numVal, setNumVal] = useState(false);
    const [isFocus, setIsFocus] = useState(false)
    const [agreeError, setAgreeError] = useState(false)
    const newContext = useContext(AppContext)

    const passwordRequirements = (passwordValue) => {
        let requirementsMet = false
        if (/[A-Z]/.test(passwordValue) && /[a-z]/.test(passwordValue) && /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(passwordValue) && /\d/.test(passwordValue) && passwordValue.length >= 8) {
            requirementsMet = true
        } else {
            requirementsMet = false
        }
        return requirementsMet
    }
    const passwordValidation = (passwordValue) => {
        if (/[A-Z]/.test(passwordValue)) {
            setUpperCase(true);
        } else {
            setUpperCase(false);
        }
        if (/[a-z]/.test(passwordValue)) {
            setLowerCase(true);
        } else {
            setLowerCase(false);
        }
        if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(passwordValue)) {
            setSpecialChar(true);
        } else {
            setSpecialChar(false);
        }

        if (/\d/.test(passwordValue)) {
            setNumVal(true);
        } else {
            setNumVal(false);
        }
        if (passwordValue.length < 8) {
            setPwdLength(false);
        } else {
            setPwdLength(true);
        }

    };
    const signup = () => {
        let data = {
            "business_name": props.firstName,
            "display_name": props.lastName,
            "email": `${props.username.toLowerCase()}@${props.domain}`,
            "password": props.password,
            "domain": props.domain,
            "recovery_email": props.email,
        }
        newContext.businessDomainSignup(data).then((success) => {
            if (success) {
                props.setSelectedPage(6)
            }
        })
    }
    const onNextClick = () => {
        if (props.password !== "" && props.confirmPassword !== "") {
            if (props.password !== props.confirmPassword) {
                setConfirmPasswordError(true)
                setConfirmPasswordErrorText("*Password do not match")
            } else {
                let valid = passwordRequirements(props.password)
                if (valid) {
                    if (!props.agreeToTerms) {
                        setAgreeError(true)
                    } else {
                        setPasswordRequirementError(false)
                        setPasswordErrorText("")
                        signup()
                    }
                } else {
                    setPasswordRequirementError(true)
                    setPasswordErrorText("*Password requirements don't match")
                }

            }
        } else if (props.password === "") {
            setPasswordError(true)
            setPasswordErrorText("* Required")
        } else if (props.confirmPassword === "") {
            setConfirmPasswordError(true)
            setConfirmPasswordErrorText("* Required")
        }
    }
    return (
        <AppContext.Consumer>
            {(context) => (
                <div className="SignupWrapper">
                    <div className="SignupWrapper-container">
                        <div className="header-container">
                            <div className="logo-container">
                                <img alt="" src={back} className="back-icon" onClick={() => {
                                    props.setSelectedPage(4)
                                }} />
                                <img alt="" src={logo} className="logo" />
                            </div>
                            <div className="signup-header">
                                Create a Strong Password
                            </div>
                            <div className="signup-subheader">
                                Set a password you will remember with a mix of letters, numbers and symbols
                            </div>
                            <div>
                                <InputContainer placeholder="Password" value={props.password} type="password" onChange={e => {
                                    props.setPassword(e.target.value)
                                    setPasswordError(false)
                                    setPasswordErrorText("")
                                    setPasswordRequirementError(false)
                                    passwordValidation(e.target.value)
                                }} error={passwordError || passwordRequirementError} errorText={passwordErrorText} onFocus={() => {
                                    setIsFocus(true)
                                }} onBlur={() => {
                                    setIsFocus(false)
                                }} />
                                {isFocus ?
                                    <div className="condition-strength-2">
                                        <div className={upperCase ? "item" : "item2"}>
                                            {" "}
                                            {upperCase ? (
                                                <img src={check} alt="" className="danger-image2" />
                                            ) : (
                                                <img src={cross} alt="" className="danger-image2" />
                                            )}
                                            Contains an uppercase letter
                                        </div>
                                        <div className={lowerCase ? "item" : "item2"}>
                                            {lowerCase ? (
                                                <img src={check} alt="" className="danger-image2" />
                                            ) : (
                                                <img src={cross} alt="" className="danger-image2" />
                                            )}
                                            Contains a lowercase letter
                                        </div>
                                        <div className={numVal ? "item" : "item2"}>
                                            {numVal ? (
                                                <img src={check} alt="" className="danger-image2" />
                                            ) : (
                                                <img src={cross} alt="" className="danger-image2" />
                                            )}
                                            Contains a number
                                        </div>
                                        <div className={specialChar ? "item" : "item2"}>
                                            {specialChar ? (
                                                <img src={check} alt="" className="danger-image2" />
                                            ) : (
                                                <img src={cross} alt="" className="danger-image2" />
                                            )}
                                            Contains a special character
                                        </div>
                                        <div className={pwdLength ? "item" : "item2"}>
                                            {pwdLength ? (
                                                <img src={check} alt="" className="danger-image2" />
                                            ) : (
                                                <img src={cross} alt="" className="danger-image2" />
                                            )}
                                            Password strength 8 characters
                                        </div>
                                    </div> : ""}
                                <InputContainer placeholder="Confirm Password" value={props.confirmPassword} type="confirmPass" onChange={e => {
                                    props.setConfirmPassword(e.target.value)
                                    setConfirmPasswordError(false)
                                    setConfirmPasswordErrorText("")

                                }} error={confirmPasswordError} errorText={confirmPasswordErrorText} />
                                <div className=
                                    {`check-container ${agreeError ? "check-error" : ""}`} onClick={() => {
                                        props.setAgreeToTerms(!props.agreeToTerms)
                                        setAgreeError(false)
                                    }}>
                                    <div className={`check-box ${agreeError ? "checked-error" : props.agreeToTerms ? "checked" : ""}`}>
                                        {props.agreeToTerms &&
                                            <img alt="" src={agreeIcon} className="check" />}
                                    </div>
                                    <div>By clicking submit I agree to the <span><a href="https://zapmail.app/terms-and-conditions/" target="_blank" rel="noopener noreferrer"  className=
                                    {`${agreeError ? "check-error" : ""}`}>Zapmail terms of service and privacy policy</a></span></div> </div>
                            </div>
                            <div className="btn-container-right">
                                <SignupButtonContainer title="Create" primary onClick={onNextClick} isLoading={context.signupLoading} />
                            </div>

                        </div>

                    </div>
                </div>
            )}
        </AppContext.Consumer>

    )
}
export default SignupWrapperFour
